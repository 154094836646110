body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrolbar {
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-color: #c4c4c4 #f7f7f7;
  scrollbar-width: thin;
}

.scrolbar::-webkit-scrollbar {
  width: 8px;
  height: 15px;
}

.scrolbar::-webkit-scrollbar-track-piece {
  background-color: #f7f7f7;
}

.scrolbar::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: #c4c4c4;
}
